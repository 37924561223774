.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appHome {
  align-items: center;
  color: white;
  justify-content: center;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.center {
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  color: white;
  text-shadow: 0 0 3px rgba(255, 145, 0, 0.87), 0 0 5px black;
}

.centerNoPad {
  display: flex;
  align-items: center;
  justify-content: center;
}

.serviceItem {
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  padding-bottom: 5px;
}

.bottomButtons {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.centerHomeText {
  align-items: center;
  justify-content: center;
}

.certifications {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EEE;
  text-align: center;
  text-justify: inter-word;
}

.horizontalSpacing {
  padding-right: 30px;
  padding-left: 30px;
}

.titleLeft {
  display: flex;
  justify-content: left;
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 5px;
  color: #1A1A18;
}

.pageContent {
  display: flex;
  justify-content: left;
  padding-top: 10px;
  padding-right: 20px;
  color: black;
  white-space: pre-line;
}

.pageContentHome {
  justify-content: center;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  color: black;
  text-align: center;
  text-justify: inter-word;
  white-space: pre-line;
}

.timeList {
  align-items: center;
  justify-content: left;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
}

.timeListBooking {
  align-items: center;
  justify-content: left;
  padding-top: 5px;
  padding-left: 20px;
  height: 38px;
}

.timeListAdmin {
  align-items: center;
  justify-content: left;
  padding-top: 5px;
  padding-left: 20px;
}

.timeListSelected {
  align-items: center;
  justify-content: left;
  background-color: #EEE;
  color: black;
  padding-top: 5px;
  padding-left: 20px;
  height: 38px;
}

.nextBookingList {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-top: 5px;
  padding-left: 5px;
}

.inputTextCorners {
  border-radius: 6px;
  border: 1px solid #333;
  background-color: #EEE;
  color: #333;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.inputTextBooking {
  border: none;
  width: 150px;
  color: #777;
}

.serviceSelected {
  background-color: #EEE;
  color: #333
}

.googleMap {
  padding-right: 20px;
  padding-left: 20px;
}

.operatorSelected {
  display: flex;
  align-items: center;
  border: 1px solid #CCC;
  background-color: #EEE;
  color: #333
}

.operatorPicture {
  display: flex;
  align-items: center;
  border: 1px solid #CCC;
  border-radius: 20px;
}

.leftNoPad {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
}

.leftPadTop {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.leftPadForm {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tableSpace {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.menuSpace {
  padding-left: 5px;
}

.buttonCustom {
  height: 35px;
}

.buttonCustomAdmin {
  height: 35px;
  width: 90px;
}

.buttonCustomSettings {
  height: 35px;
  width: 200px;
}

.buttonCustomWidth {
  height: 35px;
  width: 140px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.buttonCustomFree {
  color: rgb(91, 146, 248) !important;
  height: 35px;
  width: 90px;
}

.paidService {
  color: rgb(5, 167, 105) !important;
}

.datePicker {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
  padding-bottom: 10px;
}

.rightPadTop {
  display: flex;
  align-items: right;
  justify-content: right;
  padding-right: 20px;
}

.pointer {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/background.png");
  background-size: cover;
}
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 53%, 7%);
 --body-color: #B0E0E6  hsl(40, 64%, 95%);
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 background-color: var(--body-color);
 color: var(--text-color);
 font-size: 14px;
 font-family: var(--body-font);
}

.smallText {
  font-size: smaller;
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}

.hideItem {
  display: none;
}

.hideSlot {
  background-color: #999;
}

.pictureResize {
  width: 100%;
  height: auto;
  background-size: contain;
}

.messageWrn {
  align-items: left;
  color: red;
  justify-content: left;
}

.messageInfo {
  align-items: left;
  color: blue;
  justify-content: left;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  justify-content: center;
  align-items: center;
}

.cookieFrame {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 3px  solid orange;;
  border-radius: 10px;
}

.cookieModalBody {
  background-color: orange;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.cookieModalHeader {
  background-color: #FFF;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.cookieModalFooter {
  background-color: #FFF;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.itemsToLeft {
  align-items: left;
}

.textGray {
  color: #777;
}

.form-switch .form-check-input 
{
  width: 6em; 
  height: 1.5em;
}

.selectBox{
  width:50px;
  height:30px;
  border:0px;
  outline:none;
  background-color:white;
  color:#777;
}

.fieldset {
  margin: 2px;
  padding-bottom: 2px;    
  border-radius: 8px;
}

.legend {
  font-size: 14px;
  color: #777;
}

.appFooter {
  background-color: #1A1A18;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.appFooterContactUs {
  background-color: #AAA;
  color: white;
  font-weight: bold;
}

.arbDisclaimer {
  background-color: #1A1A18;
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.pictureResize {
  width: 100%;
  height: auto;
  background-size: contain;
}

.pictureResizeLogo {
  width: 100%;
  height: auto;
  background-size: contain;
  max-width: 400px;
}

.policy {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 12px;
  line-height: 0.9;
}

.submitMessage {
  width: 80%;
  min-height: 20vh;
  padding-left: "10px";
  border-radius: 6px;
  border: 1px solid #333;
  background-color: #EEE;
  color: #333;  
 }

 .policyButton {
  display: flex;
  align-items: center;
  text-decoration: underline;
  width:150px;
  cursor: pointer;
}

.underlineText {
  text-decoration: underline;
  cursor: pointer;
}

.pictureGallery {
  padding-top: 10px;
  padding-bottom: 20px;
}

.main {
  width: 100%;
  height: 100vh;
  padding: 10px;
}

.videoAzienda {
  width: 100%;
  padding: 15px;
}

.homePics {
  padding-left: 10px;
  padding-bottom: 10px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
/*not used, can create fading*/
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
}

.langLabel {
  min-width: 24px;
  font-weight: bold;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
	width: 35px;
	height: 60px;
	border: 2px solid white;
	border-radius: 60px;
	position: relative;
	&::before {
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: white;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 50px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 50px;
	}
}
